/*eslint-disable */
import {mapGetters } from 'vuex';
import {
  bus
} from '@/main';
import pdfViewer from './pdf-viewer';
export default {
  name: 'payment-history',
  components: {
    'pdf-viewer' : pdfViewer,
  },
  props: [],
  data () {
    return {
      paymentHistoryData:null,
      paymentHistoryResults:[],
      pdfViewerPopup:false,
      pdfViewerUrl:'',
      pdfViewerFileName:'',
    }
  },
  computed: {
    ...mapGetters([
      'getPaymentHistoryGetters'
    ]),
    getPaymentHistoryListFun(){
     this.paymentHistoryData = this.$store.getters.getPaymentHistoryGetters.data
     return;
    },
    SetIntialStatus() {
      if (this.paymentHistoryData) {
        this.paymentHistoryResults = this.paymentHistoryData.response
      }
      return;
    },
  },
  mounted () {
    this.getPaymentHistoryListFun
    this.SetIntialStatus
    bus.$on('pdfViewerPopupBus', (data) => {
      this.pdfViewerPopup = data;
    });
  },
  methods: {
    pdfViewerFun(url){
      this.pdfViewerFileName = 'invoice.pdf'
      this.pdfViewerUrl = url
      this.pdfViewerPopup = true
      document.body.classList.add('pdf_view_actv')
    },
  },
  watch:{
    getPaymentHistoryListFun(){
      return this.$store.getters.getPaymentHistoryGetters;
    },
    SetIntialStatus() {
      if (this.paymentHistoryData) {
        this.paymentHistoryResults = this.paymentHistoryData.response
      }
      return;
    },
  }
}