/*eslint-disable */
import PDFUploader from '@/components/common/pdfViewer/PDFUploader.vue'
import PDFViewer from '@/components/common/pdfViewer/PDFViewer.vue'
var $ = window.jQuery = require('jquery');
import {
  bus
} from '@/main';
export default {
  name: 'pdf-viewer',
  components: {
    PDFUploader,
    PDFViewer
  },
  props: [
    'pdfViewerURL',
    'pdfViewerFileNAME'
  ],
  data () {
    return {
      url:'',
      documentError: undefined,
      enableUploader: process.env.VUE_APP_UPLOAD_ENABLED === 'true',
    }
  },
  computed: {

  },
  mounted () {
    // console.log(this.pdfViewerURL, "Please Check the pdf url")
    this.url = this.pdfViewerURL

  },
  methods: {
    urlUpdated(url) {
      this.documentError = undefined;
    },
    onDocumentErrored(e) {
      this.documentError = e.text;
    },
    pdfViewerCloseFun(){
      bus.$emit('pdfViewerPopupBus', false);
      document.body.classList.remove('pdf_view_actv')
      document.body.classList.remove('overflow-hidden')
    },
  }
}


